import React, { Component } from 'react'
import { Provider }  from 'react-redux'
import {configureStore} from '@reduxjs/toolkit'
import {dataReducer} from './features/data'
import {mapReducer} from './features/map'
import {controllerReducer} from './features/controller'
import { demographicsReducer } from './features/demographics'
import Controller from './components/Controller'
import { BrowserRouter as Router, Route, Link } from "react-router-dom"

const store = configureStore(
{
	reducer : {data : dataReducer, map: mapReducer, controller: controllerReducer, demographics: demographicsReducer},
	middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
		immutableCheck: false,
		serializableCheck: false,
    }),//disable slow middleware
})

export default class App extends Component 
{

	render() 
	{
		return (
		<Provider store={store} >
			<Router>
				<Route path="/" component={Controller}></Route>
			</Router>
		</Provider>
		)
	}
}
