import ReactMapboxGl, {GeoJSONLayer, Layer, Feature, Marker} from "react-mapbox-gl"
import React from 'react'
import { connect } from 'react-redux'
import ccpng from '../cc-by-nc-nd.png'
import {fetchVoronoi,fetchOutline} from '../features/map'
import {setMostLikelyPlacenames} from '../features/controller'
import  {Spinner} from 'react-bootstrap'

//import * as voronoi_geojson from '../voronoi_clipped_buffered_2km_reprojected.json'
//import * as uk_geojson from '../british_isles_coastlines.json'

const Map = ReactMapboxGl(
    {
        accessToken: "pk.eyJ1IjoidGFsLWF0bGFzIiwiYSI6ImNqd3J5cnVvMzFvbGE0M2xlNmswbXhkazcifQ.JfpxNebWVOdt3xM1CNVbUg",
        dragRotate: false, //disable rotate 
		pitchWithRotate: false, //disable 3D tilt
		trackResize: true,
		touchZoomRotate: false,
		doubleClickZoom: false,
		keyboard: false,
		dragPan: false,
		scrollZoom: false,
	})
	
let blankStyle = {
		"version": 8,
		"name": "Empty",
		"metadata": {
		  "mapbox:autocomposite": true
		},
		"glyphs": "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
		"sources": {},
		"layers": [
		  {
			"id": "background",
			"type": "background",
			"paint": {
			  //"background-color": "rgba(163,183,214,255)"
			  "background-color": "rgba(183,203,234,255)"
			}
		  }
		]
	}
  
// react-mapbox-gl map of data
class MapBox extends React.Component 
{
	constructor(props) 
	{
		super(props)
		this.ref = React.createRef()
	}

	componentDidMount()
	{
		if(!this.props.map.voronoi_loaded)
		{
			this.props.fetchVoronoi()
		}
		if(!this.props.map.outline_loaded && this.props.controller.border) //DEVELOP
		{
			this.props.fetchOutline()
		}
	}

	componentDidUpdate(prevProps)
	{
	}

	cc()
	{
		return <div style={{
			position:'absolute',
			bottom:'20px',
			right:'30px',
			zIndex:'100',
			backgroundColor:'#e6e9e5',
			color:'black',
			}}>
				<img src={ccpng} width='100px'/>
			</div>
	}

	onStyleLoad(map)
	{
		//to get direct access to mapbox gl map
		this.map=map
	}

	getVoronoiGeoJSON()
	{
		//get map for previous answer

		let geojson=JSON.parse(JSON.stringify(this.props.map.voronoi_geojson))//make mutable copy

		let questions=this.props.controller.questions
		let question_id=this.props.id //passed to MapBox from Controller
		let question=questions[question_id]
		let answers=this.props.controller.answers[question_id].answer

		let prob_max=0.0
		for(let feature of geojson.features)
		{
			//probs
			let placename=feature.properties.placename
			let element=this.props.data.datasets[question_id].find((value)=>{return value.placename===placename})
			let prob=0.0
			for(let answer of answers)
			{
				if(answer!='NODATA')
				{
					//get weight
					let variant_id=question.variant.indexOf(answer)
					let weight=question.weight[variant_id]
					prob+=(parseFloat(element[answer])*weight)
				}
			}
			feature.properties.prob=prob
			prob_max = prob>prob_max ? prob : prob_max
		}
		//normalise probs
		if(prob_max>0.0)
		{
			for(let feature of geojson.features)
			{
				feature.properties.prob/=prob_max
			}
		}

		return geojson
	}

	getResultGeoJSON()
	{
		let geojson=JSON.parse(JSON.stringify(this.props.map.voronoi_geojson))//make mutable copy
		let prediction=this.props.controller.prediction

		for(let feature of geojson.features)
		{
			let placename=feature.properties.placename
			feature.properties.prob=prediction[placename]
		}	
		return geojson
	}

	getPlacenameLabels()
	{
		let placename_labels={}

		if(this.props.controller.showing=='question+map')
		{
			//questions - show place_name labels
			let version=this.props.controller.version
			placename_labels=this.props.controller.config.versions[version].placename_labels
		}
		else
		{
			//result, show top 3 voronoi locations
			for(let [placename,prob] of this.props.controller.most_likely_placenames)
			{
				//find in voronois
				let feature=this.props.map.voronoi_geojson.features.find(feature=>feature.properties.placename==placename)
				if(feature)
				{
					placename_labels[feature.properties.placename]=[feature.properties.longitude,feature.properties.latitude]
				}
			}
		}

		return <div>
			<Layer
				type='circle'
				paint={{
					'circle-radius':2,
					'circle-color': "#000",
					'circle-stroke-width': 1,
					'circle-stroke-color': '#000',
					'circle-stroke-opacity': 1
				}}>
				{Object.keys(placename_labels).map((k,i) => 
					<Feature 
						key={i} 
						coordinates={placename_labels[k]}
					/>)}
			</Layer>

			{Object.keys(placename_labels).map((k,i) => 
				<Marker 
					key={i} 
					coordinates={placename_labels[k]}
					offset={5}>
					<div style={{
						fontFamily:'Lato',
						fontSize:"12pt",
						backgroundColor:'white',
						//padding:'2px'
						//fontWeight:"bolder",
						//WebkitTextStrokeWidth: "1px",
						//WebkitTextStrokeColor: "#fff"
						}}>
						{k}
					</div>
				</Marker>)}
		</div>
	}

    render() 
    {
		//for result
		let answer=null
		let colour=Object.values(this.props.controller.config.colours)[0]

		//for question maps
		if(this.props.controller.showing=="question+map")
		{
			let question_id=this.props.id
			answer=this.props.controller.answers[question_id].answer

			//let num_colours=Object.keys(this.props.controller.config.colours).length
			colour=Object.values(this.props.controller.config.colours)[this.props.colour]
		}

		//DEVELOP
		return(this.props.map.voronoi_loaded & (this.props.map.outline_loaded | !this.props.controller.border) & this.props.data.loaded ? 
			<div id="mapbox_div">
				<Map
					style={blankStyle}
					containerStyle={{
						height: "100vh",
						width: "100%"
					}}
					fitBounds={this.props.map.bounds}
					fitBoundsOptions={{duration:0.0}}
					onStyleLoad={this.onStyleLoad.bind(this)}
					// preserveDrawingBuffer={true}
					ref={this.ref}
					>
						{/* voronois */}
						{answer!='NODATA' ? <GeoJSONLayer
							key={1}
							id="1"
							// before="1"
							data={this.props.controller.showing=='question+map' ? this.getVoronoiGeoJSON() : this.getResultGeoJSON()}
							fillLayout={{
								"visibility":"visible",
								"fill-sort-key":-1
							}}
							fillPaint={{
								"fill-color":{
									property:'prob',
									stops:[[0.0,'#ffffff'],[1.0,colour]]},
								// "fill-antialias":false,//remove outlines
								"fill-opacity":1.0,

							}}
						/> : <div style={{
									position:'absolute', 
									top:'50%', 
									right:'50%',
									fontSize: '20pt',
									backgroundColor: 'white',
									padding: '10px'
									}}>NO DATA</div>}

						{/* labels */}
						{this.getPlacenameLabels()}

						{/* outline */}
						{/* //DEVELOP */}
						{this.props.controller.border && <GeoJSONLayer
							key={3}
							id="3"
							data={this.props.map.outline_geojson}
							linePaint={{
								'line-color': 'black',
								'line-width': 2
							}}
						/>}
				</Map>
				{this.cc()}
			</div> : 
			<div style={{position:'absolute', top:'50%', right:'50%'}}>
				<Spinner animation="border" role="status" variant="secondary"/></div>)
	}
}


const mapState = (state, props) => 
{
    return state
}

const mapDispatch=
{
	fetchVoronoi, fetchOutline, setMostLikelyPlacenames
}

export default connect(mapState,mapDispatch)(MapBox)
