import {createSlice} from '@reduxjs/toolkit'
//import * as firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const demographics = createSlice(
	{
		name : "demographics",
		initialState : 
		{
			answers:{},
			gazeteer:[],
			gazeteer_loaded:false
		},
		reducers : 
		{
			setAnswers(state,action)
			{
				state.answers=action.payload.answers
			},
			loadGazeteer(state,action)
			{
				state.gazeteer=action.payload.gazeteer
			},
			setGazeteerLoaded(state,action)
			{
				state.gazeteer_loaded=action.payload.gazeteer_loaded
			},
		}
	}) 

export const {actions : demographicsActions, reducer : demographicsReducer} = demographics
export const {setAnswers,loadGazeteer,setGazeteerLoaded} = demographicsActions

export function fetchGazeteer() 
{
    return async (dispatch, getState) => 
    {
        try 
        {
			let url="https://raw.githubusercontent.com/deepthigopal/public_datasets/master/en/app/reference/places_final.csv"
			const response = await fetch(url)

			if (!response.ok) {
				throw new Error(response.status)
			}

			//parse csv -> object 
			const csv = await response.text()
			const lines=csv.split('\n')
			const header=lines[0].split(',') //name,alternate_name,county,country,grid_reference,easting,northing,latitude,longitude,postal_sort,council,council_alternate,province,broad,type,sort
			let gazeteer=[]
			for(let line of lines.slice(1))
			{
				let line_items=line.split(',')
				gazeteer.push(line_items[0]+', '+line_items[2]) //name,county
			}
            dispatch(loadGazeteer({gazeteer}))
			dispatch(setGazeteerLoaded({gazeteer_loaded:true}))
		} 
		catch(error) 
        {
            console.error(error)
        }
    }
}

export function uploadDemographics(payload)
{
	return async(dispatch,getState) =>
	{
		//upload to Firebase
		var db = firebase.firestore()
		let session_time=getState().controller.session_time

		if(payload.uid)
		{
			db.collection("users").doc(payload.uid).set(
			{
				[session_time]:
				{
					demographics:payload.answers
				}
			},{merge:true})
			.catch(function(error) 
			{
				console.error("Firebase error setting document: ", error);
			})
		}
	}
}