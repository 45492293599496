import {createSlice} from '@reduxjs/toolkit'
//import * as firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { Random } from "random-js"
const randomjs = new Random()

//create data slice of state
const controller = createSlice(
    {
        name : "controller",
        initialState : 
        {
			develop:false, //develop mode
			border:false, //show map border
			numquestions:100, //show all

			config:{},
			config_loaded:false,
			version:'', //default, override with url param ?version=blah

			questions:{}, //questions keyed by number id from csv
			questions_loaded:false,
			question_set:'primary', //primary or supplementary questions
			question_seq:[], //randomised
			question_idx:0, //question sequence index

			// core questions > prediction based on core questions > appeal to help us with our research > 
			// demographic questions > thanks, link to atlas, offer of extra questions > extra questions > 
			// prediction based on core+extra questions
			showing:"intro",//intro, question+map, waiting, predemographics, demographics, thanks1, thanks2
			answers:{}, //{question id : {answer:[],answer_text:[]}} multiple answers per question
			prediction:{}, //{placename: normalised prob}
			most_likely_placenames:[], // sorted [[placename,normalised prob]]

			uid:null, //Firebase anonymous user id
			session_time:null //time of sessions start - used as session id
        },
        reducers : 
        {
			//DEVELOP
			setShowing(state,action)
			{
				state.showing=action.payload.showing
			},
			setDevelop(state,action)
			{
				state.develop=action.payload.develop
			},
			setBorder(state,action)
			{
				state.border=action.payload.border
			},
			setNumquestions(state,action)
			{
				state.numquestions=action.payload.numquestions
			},

			setUid(state,action)
			{
				state.uid=action.payload.uid
			},
			setSessionTime(state,action)
			{
				state.session_time=action.payload.session_time
			},
			setVersion(state,action)
			{
				state.version=action.payload.version
			},
			loadConfig(state, action) 
            {
				state.config = action.payload.config
			},
            setConfigLoaded(state, action) 
            {
                state.config_loaded = action.payload.config_loaded
			},
			loadQuestions(state, action) 
            {
				state.questions = action.payload.questions
			},
            setQuestionsLoaded(state, action) 
            {
                state.questions_loaded = action.payload.questions_loaded
			},
			setQuestionIdx(state,action)
			{
				state.question_idx=action.payload.question_idx
			},
			startQuestions(state,action)
			{
				//randomise question sequence
				// state.question_seq = state.config.versions[state.version].questions_primary.map((i)=>state.questions[i])
				// .map((a) => ({sort: Math.random(), value: a}))
				// .sort((a, b) => a.sort - b.sort)
				// .map((a) => a.value)

				state.question_seq=state.config.versions[state.version].questions_primary.map((i)=>state.questions[i])
				randomjs.shuffle(state.question_seq)

				//DEVELOP
				state.question_seq=state.question_seq.slice(0,state.numquestions)

				state.showing="question+map"
			},
			setAnswer(state,action)
			{
				state.answers[action.payload.question_id]={answer:action.payload.answer,answer_text:action.payload.answer_text}
			},
			nextQuestion(state,action)
			{
				state.question_idx++
				//state.showing="question"
			},
			waitForResult(state,action)
			{
				state.showing="waiting"
			},
			startPredemographics(state,action)
			{
				state.showing="predemographics"
			},
			startDemographics(state,action)
			{
				state.showing="demographics"
			},
			finishedDemographics(state,action)
			{
				state.showing="thanks1"
			},
			setPredictionResult(state,action)
			{
				state.prediction=action.payload.prediction
			},
			setMostLikelyPlacenames(state,action)
			{
				state.most_likely_placenames=action.payload.most_likely_placenames
			},
			moreQuestions(state,action)
			{
				state.question_set='supplementary'
				state.question_idx=0

				// //pick N from supplementary

				//randomise question sequence
				// state.question_seq = state.config.versions[state.version].questions_supplementary.map((i)=>state.questions[i])
				// .map((a) => ({sort: Math.random(), value: a}))
				// .sort((a, b) => a.sort - b.sort)
				// .map((a) => a.value)

				//1st priority
				state.question_seq=state.config.versions[state.version].questions_supplementary.map((i)=>state.questions[i])
				randomjs.shuffle(state.question_seq)

				//2nd priority
				if('questions_supplementary_2' in state.config.versions[state.version])
				{
					var question_seq_2=state.config.versions[state.version].questions_supplementary_2.map((i)=>state.questions[i])
					randomjs.shuffle(question_seq_2)
					state.question_seq=state.question_seq.concat(question_seq_2)					
				}

				//DEVELOP
				//state.question_seq=state.question_seq.slice(0,state.numquestions)

				state.showing="question+map"
			}
        }
    })

//RSK boilerplate
//(object destructuring - assigning new variable names)
export const {actions : controllerActions, reducer : controllerReducer} = controller
export const {setUid,setSessionTime,setShowing,setDevelop,setBorder,setNumquestions,
			setVersion,loadConfig,setConfigLoaded,loadQuestions,setQuestionsLoaded,setQuestionIdx,
			startQuestions,setAnswer,nextQuestion,startDemographics,startPredemographics,finishedDemographics,
			setPredictionResult,setMostLikelyPlacenames,waitForResult,moreQuestions} = controllerActions
 
export function fetchConfig() 
{
    return async (dispatch, getState) => 
    {
        try 
        {
			const url="https://storage.googleapis.com/tweetolectology_app_config/config.json"+"?"+String(Math.random()) //add bogus variable to get round cache issue
            const response = await fetch(url)

            if (!response.ok) {
                throw new Error(response.status)
            }

			const config = await response.json()

            dispatch(loadConfig({config}))
			dispatch(setVersion({version:config.version}))
			dispatch(setConfigLoaded({config_loaded:true}))
			dispatch(fetchQuestions())
        } catch(error) 
        {
            console.error(error)
        }
    }
}

export function fetchQuestions() 
{
    return async (dispatch, getState) => 
    {
        try 
        {
			//debugger
			let state=getState()
			let version=state.controller.version
			let question_locations=state.controller.config.versions[version].questions
			let questions={}

			for(let url of question_locations)
			{
				//const url=question_locations[0]
				url+=("?"+String(Math.random())) //add bogus variable to get round cache issue
				const response = await fetch(url)

				if (!response.ok) {
					throw new Error(response.status)
				}

				//parse csv -> object 
				//one question over multiple lines, duplicating all fields but variant, variant_text and weight
				const questions_csv = await response.text()
				const lines=questions_csv.split('\n')
				const header=lines[0].split(',') //question,question_name,dataset,question_text,variant,variant_text,weight
				const NUMCOLS=7 //6 without, or 7 with weight
				let question_id=null
				let question={}
				for(let line of lines.slice(1))
				{
					let line_items=line.split(',')

					//correct for commas within quotes
					let line_items_corrected=[]
					let i=0
					for(let i=0; i<line_items.length; i++)
					{
						let item=line_items[i]
						if(item.includes('"'))
						{
							item+=","+line_items[i+1]
							i++
						}
						line_items_corrected.push(item)
					}
					line_items=line_items_corrected

					//skip empty lines
					if(line_items.length<NUMCOLS-1)
					{
						continue
					}

					if(line_items[0] != question_id)
					{
						//add to questions when finished
						if(question_id)
						{
							questions[question_id]=question
						}
						//start new question
						question={}
						question_id=line_items[0]
						question['question']=line_items[0]
						question['question_name']=line_items[1]
						question['dataset']=line_items[2]
						//question['question_text']=line_items[3].replaceAll('"','') //remove "s
						//replaceAll too new for older browsers
						question['question_text']=line_items[3].replace(/"/g,'') //remove "s
						question['variant']=[line_items[4]]
						question['variant_text']=[line_items[5].replace(/"/g,'')]
						if(line_items.length==NUMCOLS)
						{
							question['weight']=[parseFloat(line_items[6])]
						}
						else
						{
							question['weight']=[1.0]
						}
					}
					else
					{
						//continue current question
						question['variant'].push(line_items[4])
						question['variant_text'].push(line_items[5].replace(/"/g,''))
						if(line_items.length==NUMCOLS)
						{
							question['weight'].push(parseFloat(line_items[6]))
						}
						else
						{
							question['weight'].push(1.0)
						}
					}
				}
				//add last question
				questions[question_id]=question

			}
            dispatch(loadQuestions({questions}))
			dispatch(setQuestionsLoaded({questions_loaded:true}))
		} 
		catch(error) 
        {
            console.error(error)
        }
    }
}

//implemented as action creator so can access other slice's state
export const computeResult= ()=>(dispatch, getState) => 
{
		//multiply probs for each answer
		let state=getState()
		let geojson=state.map.voronoi_geojson
		let answers=state.controller.answers
		let prob_mul_max=0.0 //max of prob_mul used to normalise
		//let prob_max=0.0 //max single prob to find most likely location

		let prediction={} //result {placename:prob}

		for(let feature of geojson.features)
		{
			//probs
			let placename=feature.properties.placename
			let prob_mul=1.0

			for(let question_id of Object.keys(answers))
			{
				let element=state.data.datasets[question_id].find((value)=>{return value.placename===placename})
				let answer=answers[question_id].answer //now array for multiple answers

				//let prob=0.0 add
				let prob=1.0 //multiply
				for(let a of answer)
				{
					if(a!='NODATA')
					{
						//prob+=parseFloat(element[a])
						prob*=parseFloat(element[a])
					}
				}
				if(prob>0.0) //in case of NODATA
				{
					prob_mul*=prob
				}
			}
			prediction[placename]=prob_mul

			//max of multiplied probs
			prob_mul_max = prob_mul>prob_mul_max ? prob_mul : prob_mul_max
		}

		let placename_probs=[] //for sorting
		if(prob_mul_max==0.0)
		{
			//console.log('++++ prob_mul_max==0.0 ++++')
			prob_mul_max=1.0
		}

		for(let [placename,prob] of Object.entries(prediction))
		{
			//normalise probs
			prob/=prob_mul_max
			prediction[placename]=prob

			//find most likely
			// if(feature.properties.prob>prob_max)
			// {
			// 	most_likely_placenames=feature.properties.placename
			// 	prob_max=feature.properties.prob
			// }

			placename_probs.push([placename,prob])
		}

		dispatch(setPredictionResult({prediction}))

		//get N most likely
		let N=3
		placename_probs.sort((a,b)=>b[1]-a[1])
		let most_likely_placenames=placename_probs.slice(0,N)

		dispatch(setMostLikelyPlacenames({most_likely_placenames}))
		
		if(state.controller.question_set=='primary')
		{
			dispatch(setShowing({showing:'predemographics'}))
		}
		else
		{
			dispatch(setShowing({showing:'thanks2'}))
		}

		//upload to Firebase
		let most_likely_placenames_object={}
		for(let[index,[place,prob]] of most_likely_placenames.entries())
		{
			most_likely_placenames_object[index]={place,prob}
		}
		var db = firebase.firestore()
		let session_time=getState().controller.session_time

		if(state.controller.uid)
		{
			db.collection("users").doc(state.controller.uid).set(
				{
					[session_time]:
					{
						prediction:most_likely_placenames_object
					}
				},{merge:true})
			.catch(function(error) {
				console.error("Firebase error setting document: ", error);
			})
		}
	//}
}

export function initFirebase() 
{
    return async (dispatch, getState) => 
    {
        try 
        {
			var firebaseConfig = 
			{
				apiKey: "AIzaSyB_WGegBEChNBieJQ86GYZO_AJiQSeQ2FI",
				authDomain: "tweetolectology-app.firebaseapp.com",
				databaseURL: "https://tweetolectology-app.firebaseio.com",
				projectId: "tweetolectology-app",
				storageBucket: "tweetolectology-app.appspot.com",
				messagingSenderId: "1018030842476",
				appId: "1:1018030842476:web:59d971b95e53b3e90eae49",
				measurementId: "G-1Q15YMGHY6"
			}
			
			var fb = firebase.initializeApp(firebaseConfig)
			//console.log(fb)

			// sign in as anonymous user so can write to firestore
			firebase.auth().signInAnonymously().then(() => 
			{
				// Signed in..
				console.log('Firebase signed in')
			  })
			  .catch((error) => {
				console.log("Firebase sign in error",error)
			})

			//get firebase anonymous user id to use as session id
			firebase.auth().onAuthStateChanged((user) => 
			{
				if (user) 
				{
					// User is signed in, see docs for a list of available properties
					// https://firebase.google.com/docs/reference/js/firebase.User
					let uid = user.uid
					console.log('Firebase uid',uid)
					dispatch(setUid({uid}))

					//create session time/id
					let session_time=new Date().toISOString()
					console.log('Session time',session_time)
					dispatch(setSessionTime({session_time}))

				} else 
				{
					// User is signed out
				}
			})
						
        } catch(error) 
        {
            console.error(error)
        }
    }
}

export function uploadAnswer(payload)
{
	return async(dispatch,getState) =>
	{
		//upload to Firebase
		var db = firebase.firestore()
		//let date=new Date().toISOString()
		let session_time=getState().controller.session_time
		if(payload.uid)
		{
			//db.collection("users/"+payload.uid+"/sessions").doc(payload.sessionid).set(
			db.collection("users").doc(payload.uid).set(
				{
					[session_time]:
					{
						answers:
						{
							[payload.question_id]:
							{
								variant:payload.answer,
								variant_text:payload.answer_text
							}
						}
					}
				},{merge:true})
			// .then(function(docRef) {
			// 	console.log("Document written with ID: ", docRef.id);
			// })
			.catch(function(error) {
				console.error("Firebase error setting document: ", error);
			})
		}
	}
}
