import React from 'react'
import { connect } from 'react-redux'
import { Button,Container,Row,Col,Form,ToggleButton,ToggleButtonGroup,Spinner} from 'react-bootstrap'
import { setAnswers,uploadDemographics,fetchGazeteer } from '../features/demographics'
import { finishedDemographics } from '../features/controller'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

class Demographics extends React.Component 
{
	constructor(props) 
	{
		super(props)
		this.state = 
		{
			done_quiz:[],
			birth_year:"",//year of birth input
			gender:"",
			leave_education:[],
			parents_university:"",
			childhood_uk:"",
			childhood_longest:[],
			childhood_other:[],
			adulthood_uk:"",
			adulthood_longest:[],
			adulthood_other:[]
		}
		this.ref=React.createRef()
	}

	componentDidMount()
	{		
		this.props.fetchGazeteer()
	}

	onAnswer(event)
	{
		this.props.setAnswers({answers:this.state})
		this.props.uploadDemographics({uid:this.props.controller.uid,answers:this.state})
		this.props.finishedDemographics()
	}

	handleYearChange(event)
	{
		let value=event.target.value
		let pattern=/^(|[1-2]|[1-2][0,9]|[1-2][0,9][0-9]|[1-2][0,9][0-9][0-9])$/ //1900-2099

		if(pattern.test(value))
		{
			this.setState({...this.state, birth_year: value})
		}
	}

	render () 
	{
		let background_colour=this.props.controller.config.background_colour

		let version=this.props.controller.version
		let question_texts=this.props.controller.config.versions[version].demographics.question_texts
		let ui_text=this.props.controller.config.versions[version].demographics.ui_text

		return this.props.demographics.gazeteer_loaded ? <div style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			// height: '100vh',
			backgroundColor:background_colour
		}}>
			<Container style={{backgroundColor:background_colour}}>
				{/* 0 */}
				<Row style={{marginTop:'50px'}}>
					{/* xs means everything from xs upwards. Total 12 cols width */}		
					<Col xs={2}></Col> 
					<Col xs={8}>
						<h4>{question_texts['done_quiz']}</h4>
					</Col>
					<Col xs={2}></Col>
				</Row>
				<Row style={{marginTop:'10px'}}>
					<Col xs={2}></Col>
					<Col xs={{span:8, offset:1}}>
						<ToggleButtonGroup 
							type="radio"
							name="done_quiz" 
							vertical
							value={this.state.done_quiz}
							onChange={(val)=>{
								this.setState({...this.state,done_quiz:val})
							}}
							>
							<ToggleButton
								key={`toggle-yes`}
								variant='outline-dark'
								size="lg"
								type='radio'
								value='yes'
								>
								{ui_text['yes']}
							</ToggleButton>	
							<ToggleButton
								key={`toggle-no`}
								variant='outline-dark'
								size="lg"
								type='radio'
								value='no'
								>
								{ui_text['no']}
							</ToggleButton>	
						</ToggleButtonGroup>
					</Col>
					<Col xs={2}></Col>
				</Row>

				{/* 1 */}
				<Row style={{marginTop:'50px'}}>
					<Col xs={2}></Col> 
					<Col xs={8}>
					<h4>{question_texts['birth_year']}</h4>
					</Col>
					<Col xs={2}></Col>
				</Row>
				<Row style={{marginTop:'10px'}}>
					<Col xs={2}></Col>
					<Col xs={{span:8, offset:1}}>
						<Row style={{marginTop:'10px'}}>
							<Form>
								<Form.Control 
									as="input" 
									rows={1} 
									onChange={this.handleYearChange.bind(this)}
									value={this.state.birth_year}
									placeholder="Year of Birth (YYYY)"
									/>
							</Form>
						</Row>
					</Col>
					<Col xs={2}></Col>
				</Row>

				{/* 2 */}
				<Row style={{marginTop:'50px'}}>
					<Col xs={2}></Col> 
					<Col xs={8}>
					<h4>{question_texts['gender']}</h4>
					</Col>
					<Col xs={2}></Col>
				</Row>
				<Row style={{marginTop:'10px'}}>
					<Col xs={2}></Col>
					<Col xs={{span:8, offset:1}}>
						<Row style={{marginTop:'10px'}}>
							<Form>
								<Form.Control 
									as="input" 
									rows={1} 
									onChange={((event)=>{this.setState({...this.state, gender:event.target.value})}).bind(this)}
									value={this.state.gender}
									placeholder="Gender"
									/>
							</Form>
						</Row>
					</Col>
					<Col xs={2}></Col>
				</Row>

				{/* 3 */}
				<Row style={{marginTop:'50px'}}>
					<Col xs={2}></Col> 
					<Col xs={8}>
					<h4>{question_texts['leave_education']}</h4>
					</Col>
					<Col xs={2}></Col>
				</Row>
				<Row style={{marginTop:'10px'}}>
					<Col xs={2}></Col>
					<Col xs={{span:8, offset:1}}>
						<ToggleButtonGroup 
							type="radio"
							name="leave_education" 
							vertical
							value={this.state.leave_education}
							onChange={(val)=>{
								this.setState({...this.state,leave_education:val})
							}}
							>
							<ToggleButton
								key={`toggle-16`}
								variant='outline-dark'
								size="lg"
								type='radio'
								value='16'
								>
								16
							</ToggleButton>	
							<ToggleButton
								key={`toggle-18`}
								variant='outline-dark'
								size="lg"
								type='radio'
								value='18'
								>
								18
							</ToggleButton>	
							<ToggleButton
								key={`toggle-uni`}
								variant='outline-dark'
								size="lg"
								type='radio'
								value='university'
								>
								{ui_text['university']}
							</ToggleButton>	
						</ToggleButtonGroup>
					</Col>
					<Col xs={2}></Col>
				</Row>

				{/* 4 */}
				<Row style={{marginTop:'50px'}}>
					<Col xs={2}></Col> 
					<Col xs={8}>
					<h4>{question_texts['parents_university']}</h4>
					</Col>
					<Col xs={2}></Col>
				</Row>
				<Row style={{marginTop:'10px'}}>
					<Col xs={2}></Col>
					<Col xs={{span:8, offset:1}}>
						<ToggleButtonGroup 
							type="radio"
							name="parents_university" 
							vertical
							value={this.state.parents_university}
							onChange={(val)=>{
								this.setState({...this.state,parents_university:val})
							}}
							>
							<ToggleButton
								key={`toggle-both`}
								variant='outline-dark'
								size="lg"
								type='radio'
								value='both'
								>
								{ui_text['both']}
							</ToggleButton>	
							<ToggleButton
								key={`toggle-one`}
								variant='outline-dark'
								size="lg"
								type='radio'
								value='one'
								>
								{ui_text['one']}
							</ToggleButton>	
							<ToggleButton
								key={`toggle-neither`}
								variant='outline-dark'
								size="lg"
								type='radio'
								value='neither'
								>
								{ui_text['neither']}
							</ToggleButton>	
						</ToggleButtonGroup>
					</Col>
					<Col xs={2}></Col>
				</Row>

				{/* 5a */}
				<Row style={{marginTop:'50px'}}>
					<Col xs={2}></Col> 
					<Col xs={8}>
					<h4>{question_texts['childhood_uk']}</h4>
					</Col>
					<Col xs={2}></Col>
				</Row>
				<Row style={{marginTop:'10px'}}>
					<Col xs={2}></Col>
					<Col xs={{span:8, offset:1}}>
						<ToggleButtonGroup 
							type="radio"
							name="childhood_uk" 
							vertical
							value={this.state.childhood_uk}
							onChange={(val)=>{
								this.setState({...this.state,childhood_uk:val})
							}}
							>
							<ToggleButton
								key={`toggle-yes`}
								variant='outline-dark'
								size="lg"
								type='radio'
								value='yes'
								>
								{ui_text['yes']}
							</ToggleButton>	
							<ToggleButton
								key={`toggle-no`}
								variant='outline-dark'
								size="lg"
								type='radio'
								value='no'
								>
								{ui_text['no']}
							</ToggleButton>	
						</ToggleButtonGroup>
					</Col>
					<Col xs={2}></Col>
				</Row>

				{/* 5b */}
				<Row style={{marginTop:'50px'}}>
					<Col xs={2}></Col> 
					<Col xs={8}>
					<h4>{question_texts['childhood_longest']}</h4>
					</Col>
					<Col xs={2}></Col>
				</Row>
				<Row style={{marginTop:'10px'}}>
					<Col xs={2}></Col>
					<Col xs={8}>
						<Row style={{marginTop:'10px'}}>
							<Typeahead
								id="childhood_longest"
								labelKey="childhood_longest"
								ref={this.ref}
								//multiple
								onChange={(event)=>{console.log(event);this.setState({...this.state, childhood_longest:event})}}
								options={this.props.demographics.gazeteer}
								placeholder="Select a place"
								selected={this.state.childhood_longest}
								style={{width:'100%'}}
								minLength={3}
								filterBy={(option, props) => {
									//filter on place name only
									let place=option.split(',')[0]
									//let county=option.split(',')[1]
									return place.toLowerCase().includes(props.text.toLowerCase())
									}}
								>
							</Typeahead>
						</Row>				
					</Col>
					<Col xs={2}></Col>
				</Row>

				{/* 6 */}
				<Row style={{marginTop:'50px'}}>
					<Col xs={2}></Col> 
					<Col xs={8}>
					<h4>{question_texts['childhood_other']}</h4>
					</Col>
					<Col xs={2}></Col>
				</Row>
				<Row style={{marginTop:'10px'}}>
					<Col xs={2}></Col>
					<Col xs={8}>
						<Row style={{marginTop:'10px'}}>
							<Typeahead
								id="childhood_other"
								labelKey="childhood_other"
								multiple
								onChange={(event)=>{console.log(event);this.setState({...this.state, childhood_other:event})}}
								options={this.props.demographics.gazeteer}
								placeholder="Add places"
								selected={this.state.childhood_other}
								style={{width:'100%'}}
								minLength={3}
								filterBy={(option, props) => {
									//filter on place name only
									let place=option.split(',')[0]
									//let county=option.split(',')[1]
									return place.toLowerCase().includes(props.text.toLowerCase())
									}}
								>
							</Typeahead>
						</Row>
					</Col>
					<Col xs={2}></Col>
				</Row>

				{/* 7a */}
				<Row style={{marginTop:'50px'}}>
					<Col xs={2}></Col> 
					<Col xs={8}>
					<h4>{question_texts['adulthood_uk']}</h4>
					</Col>
					<Col xs={2}></Col>
				</Row>
				<Row style={{marginTop:'10px'}}>
					<Col xs={2}></Col>
					<Col xs={{span:8, offset:1}}>
						<ToggleButtonGroup 
							type="radio"
							name="adulthood_uk" 
							vertical
							value={this.state.adulthood_uk}
							onChange={(val)=>{
								this.setState({...this.state,adulthood_uk:val})
							}}
							>
							<ToggleButton
								key={`toggle-yes`}
								variant='outline-dark'
								size="lg"
								type='radio'
								value='yes'
								>
								{ui_text['yes']}
							</ToggleButton>	
							<ToggleButton
								key={`toggle-no`}
								variant='outline-dark'
								size="lg"
								type='radio'
								value='no'
								>
								{ui_text['no']}
							</ToggleButton>	
						</ToggleButtonGroup>
					</Col>
					<Col xs={2}></Col>
				</Row>
				
				{/* 7b */}
				<Row style={{marginTop:'50px'}}>
					<Col xs={2}></Col> 
					<Col xs={8}>
					<h4>{question_texts['adulthood_longest']}</h4>
					</Col>
					<Col xs={2}></Col>
				</Row>
				<Row style={{marginTop:'10px'}}>
					<Col xs={2}></Col>
					<Col xs={8}>
						<Row style={{marginTop:'10px'}}>
							<Typeahead
								id="adulthood_longest"
								labelKey="adulthood_longest"
								ref={this.ref}
								//multiple
								onChange={(event)=>{console.log(event);this.setState({...this.state, adulthood_longest:event})}}
								options={this.props.demographics.gazeteer}
								placeholder="Select a place"
								selected={this.state.adulthood_longest}
								style={{width:'100%'}}
								minLength={3}
								filterBy={(option, props) => {
									//filter on place name only
									let place=option.split(',')[0]
									//let county=option.split(',')[1]
									return place.toLowerCase().includes(props.text.toLowerCase())
									}}
								>
							</Typeahead>
						</Row>
					</Col>
					<Col xs={2}></Col>
				</Row>

				{/* 8 */}
				<Row style={{marginTop:'50px'}}>
					<Col xs={2}></Col> 
					<Col xs={8}>
					<h4>{question_texts['adulthood_other']}</h4>
					</Col>
					<Col xs={2}></Col>
				</Row>
				<Row style={{marginTop:'10px'}}>
					<Col xs={2}></Col>
					<Col xs={8}>
						<Row style={{marginTop:'10px'}}>
							<Typeahead
								id="adulthood_other"
								labelKey="adulthood_other"
								multiple
								onChange={(event)=>{console.log(event);this.setState({...this.state, adulthood_other:event})}}
								options={this.props.demographics.gazeteer}
								placeholder="Add places"
								selected={this.state.adulthood_other}
								style={{width:'100%'}}
								minLength={3}
								filterBy={(option, props) => {
									//filter on place name only
									let place=option.split(',')[0]
									//let county=option.split(',')[1]
									return place.toLowerCase().includes(props.text.toLowerCase())
									}}
								>
							</Typeahead>
						</Row>
					</Col>
					<Col xs={2}></Col>
				</Row>

				{/* Done */}
				<Row style={{marginTop:'50px', marginBottom:'50px'}}>
					<Col xs={2}></Col>
					<Col xs={8}>
						<Button 
							size="lg"
							variant="outline-dark"
							onClick={this.onAnswer.bind(this)}
							answer={this.state.gender}>
							{this.props.controller.config.versions[version].ui_text['done']}
						</Button>
					</Col>
					<Col xs={2}></Col>
				</Row>
			</Container>
		</div> : <div style={{position:'absolute', top:'50%', right:'50%'}}>
				<Spinner animation="border" role="status" variant="secondary"/></div>
	}
}

const mapState = (state, props) => 
{
	return state
}

const mapDispatch=
{
	setAnswers,uploadDemographics,finishedDemographics,fetchGazeteer
}

export default connect(mapState,mapDispatch)(Demographics)
