import {createSlice} from '@reduxjs/toolkit'

//ducks style using RSK helper functions

//create map slice of state
const map = createSlice(
    {
        name : "map",
        initialState : 
        {
			voronoi_geojson: {},
			voronoi_loaded: false,
			outline_geojson: {},
			outline_loaded: false,
			bounds: [[-11.0, 49], [2.5, 61]], //British Isles lon, lat
        },
        reducers : 
        {
			loadVoronoi(state,action)
			{
				state.voronoi_geojson=action.payload.voronoi_geojson
			},
			setVoronoiLoaded(state,action)
			{
				state.voronoi_loaded=action.payload.voronoi_loaded
			},
			loadOutline(state,action)
			{
				state.outline_geojson=action.payload.outline_geojson
			},
			setOutlineLoaded(state,action)
			{
				state.outline_loaded=action.payload.outline_loaded
			}
        }
    })

//RSK boilerplate
//(object destructuring - assigning new variable names)
export const {actions : mapActions, reducer : mapReducer} = map
export const {loadVoronoi,setVoronoiLoaded,loadOutline,setOutlineLoaded} = mapActions 

export function fetchVoronoi() 
{
    return async (dispatch, getState) => 
    {
        try 
        {
			let state=getState()
			let version=state.controller.version
			let url=state.controller.config.versions[version].voronoi_geojson
			//const url="http://localhost:3000/sequence.json"
			//const url="https://storage.googleapis.com/tweetolectology_app_config/sequence.json"
            const response = await fetch(url)

            if (!response.ok) {
                throw new Error(response.status)
            }

			const voronoi_geojson = await response.json()

            dispatch(loadVoronoi({voronoi_geojson}))
			dispatch(setVoronoiLoaded({voronoi_loaded: true}))
        } catch(error) 
        {
            console.error(error)
        }
    }
}

export function fetchOutline() 
{
    return async (dispatch, getState) => 
    {
        try 
        {
			//debugger
			let state=getState()
			let version=state.controller.version
			let url=state.controller.config.versions[version].outline_geojson
            const response = await fetch(url)

            if (!response.ok) {
                throw new Error(response.status)
            }

			let outline_geojson = await response.json()
			//outline_geojson={...outline_geojson,features:[outline_geojson.features[3]]}

            dispatch(loadOutline({outline_geojson}))
			dispatch(setOutlineLoaded({outline_loaded: true}))
        } catch(error) 
        {
            console.error(error)
        }
    }
}