import {createSlice} from '@reduxjs/toolkit'

const data = createSlice(
    {
        name : "data",
        initialState : 
        {
			loaded:false,
			datasets:{} //keyed by question_id
        },
        reducers : 
        {
            setDataLoaded(state, action) 
            {
                state.loaded = action.payload.loaded
            },
            loadDataset(state, action) 
            {
				//check for zeros in probs columns, and set any to lowest value in column
				let dataset_mod=JSON.parse(JSON.stringify(action.payload.dataset))//make mutable copy
				let columns=Object.keys(action.payload.dataset[0])
				let prob_columns=columns.filter(col=>{return !['placename','longitude','latitude'].includes(col)})
				//console.log(prob_columns)
				for (let prob_column of prob_columns)
				{
					let lowest_prob=1.0
					let zero_indices=[]
					for (let [idx,row] of Object.entries(action.payload.dataset))
					{
						let prob=parseFloat(row[prob_column]) //prob is actually a string, but using floats to mark changed values - both passed through parseFloat in map
						if(prob==0.0)
						{
							zero_indices.push(parseInt(idx))
						}
						else
						{
							if (prob<lowest_prob)
							{
								lowest_prob=prob
							}
						}
					}

					//modify data
					for (let idx of zero_indices)
					{
						dataset_mod[idx][prob_column]=lowest_prob
					}
					
					//console.log(prob_column,zero_indices.length,'zeros of',action.payload.dataset.length,'lowest',lowest_prob)
				}
				
                //state.datasets[action.payload.question_id] = action.payload.dataset
				state.datasets[action.payload.question_id] = dataset_mod
				//debugger
            }
        }
    }) 

export const {actions : dataActions, reducer : dataReducer} = data
export const {setDataLoaded, loadDataset} = dataActions

//fetch dataset as csv from public_datasets on github
export function fetchDataset(csv_name,question_id) 
{
    return async (dispatch, getState) => 
    {
        try 
        {
			dispatch(setDataLoaded({loaded: false}))

			let state=getState()
			let version=state.controller.version
			let url=state.controller.config.versions[version].datasets+csv_name+'.csv'
			//const url="https://raw.githubusercontent.com/deepthigopal/public_datasets/master/en/app/datasets/"+csv_name

            const response = await fetch(url)

            if (!response.ok) {
                throw new Error(response.status)
            }

			//parse csv -> object 
			const rows_string = await response.text()
			const rows_lines=rows_string.split('\n')
			const header=rows_lines[0].split(',')
			let rows=[]
			for(let row_line of rows_lines.slice(1))
			{
				let row_line_string=row_line.split(',')
				let row={}
				for(let [i,column] of header.entries())
				{
					if(column)
					{
						row[column]=row_line_string[i]
					}
				}
				rows.push(row)
			}

            dispatch(loadDataset({question_id,dataset:rows}))
			dispatch(setDataLoaded({loaded: true}))

		} 
		catch(error) 
        {
            console.error(error)
        }
    }
}
