import React from 'react'
import { connect } from 'react-redux'
import { setShowing,setDevelop,setBorder,setNumquestions,
		setVersion,fetchConfig,startQuestions,setAnswer,uploadAnswer,nextQuestion,
		computeResult,waitForResult,startDemographics,startPredemographics,initFirebase,moreQuestions } from '../features/controller'
import { fetchDataset } from '../features/data'
import { Button,Container,Row,Col,Spinner,ProgressBar,ToggleButton,ToggleButtonGroup } from 'react-bootstrap'
import MapBox from './MapBox'
import Demographics from './Demographics'

class Controller extends React.Component 
{
	constructor(props) 
	{
		super(props)
		this.state={answer_toggles:[]}
	}

	componentDidMount()
	{	
		//set version if given as url param ?version=blah, otherwise default used
		let search_params=new URLSearchParams(this.props.location.search) //prop passed from react router
		//console.log('URL PARAMS',search_params)

		if(search_params.has("develop"))
		{		
			this.props.setDevelop({develop:true})
		}

		if(search_params.has("border"))
		{		
			this.props.setBorder({border:true})
		}

		if(search_params.has("version"))
		{
			let version=search_params.get("version")
			this.props.setVersion({version})
		}

		if(search_params.has("showing"))
		{	
			let showing=search_params.get("showing")	
			this.props.setShowing({showing})
		}

		if(search_params.has("questions"))
		{	
			let numquestions=parseInt(search_params.get("questions"))
			this.props.setNumquestions({numquestions})
		}

		//start loading sequence
		this.props.fetchConfig()

		//init Firebase
		this.props.initFirebase()
	}

	componentDidUpdate(prevProps)
	{
		if(this.props.controller.showing=="waiting" && this.props.data.loaded)
		{
			this.props.computeResult()	
		}	

		if(!prevProps.controller.config_loaded && this.props.controller.config_loaded)
		{
			this.injectColoursCSS()
		}

		//tweet button
		if(window.twttr.widgets)
		{
			window.twttr.widgets.load()
		}
	}

	injectColoursCSS()
	{
		//generate css from config colours and inject into DOM for bootstrap buttons

		let css=Object.values(this.props.controller.config.colours).reduce((previous,colour,i)=>previous+
			`.colour-${i}
			{
				color:${colour};
				background-color: white;
				border-color: ${colour};
			}
			.btn-button-${i}
			{
				color:${colour};
				background-color: white;
				border-color: ${colour};
			}
			.btn-button-${i}:hover
			{
				color:${colour};
				background-color: white;
				border-color: ${colour};
			}
			.btn-button-${i}.active
			{
				color:white;
				background-color: ${colour};
				border-color: ${colour};
			}
			`,"")

		//console.log(css)

		const style = document.createElement('style')
		style.textContent = css
		document.head.append(style)
	}

	onIntroDone()
	{
		this.props.startQuestions()
	}

	onQuestionAnswered(event)
	{
		//start to load data for map
		let question_idx=this.props.controller.question_idx
		let question_id=parseInt(this.props.controller.question_seq[question_idx].question)
		let csv_name=this.props.controller.question_seq[question_idx].dataset
		this.props.fetchDataset(csv_name,question_id)

		//get answers from toggled buttons
		// answer::answer_text
		// answer_text included because answer can be NO_DATA
		let answer=[]
		let answer_text=[]
		for(let answer_toggle of this.state.answer_toggles)
		{
			let answer_answer_text=answer_toggle.split('::')
			answer.push(answer_answer_text[0])
			answer_text.push(answer_answer_text[1])
		}

		//store answers in local state
		this.props.setAnswer({question_id,answer,answer_text})

		//and upload to firebase
		let uid=this.props.controller.uid
		//let sessionid=this.props.controller.sessionid
		this.props.uploadAnswer({uid,question_id,answer,answer_text})

		//clear togglebuttons
		this.setState({answer_toggles:[]})

		//next
		if(this.props.controller.question_idx<this.props.controller.question_seq.length-1 
			&& event.target.textContent==this.props.controller.config.versions[this.props.controller.version].ui_text['next'])
		{				
			this.props.nextQuestion()
		}
		else
		{
			this.props.waitForResult()
		}
	}

	onFinishQuestions(event)
	{
		//supplementary questions, skip to end
		this.props.waitForResult()
	}

	onPredemographicsDone()
	{
		this.props.startDemographics()
	}

	onUpload()
	{
		this.props.uploadAnswers(this.props.controller.answers)
	}

	onMore()
	{
		this.props.moreQuestions()
	}

	onNothanks()
	{
		this.props.setShowing({showing:"thanks2"})
	}

    render() 
    {
		if(!this.props.controller.config_loaded)
		{
			return <div style={{position:'absolute', top:'50%', right:'50%'}}>
				<Spinner animation="border" role="status" variant="secondary"/></div>
		}

		let version=this.props.controller.version
		let ui_text=this.props.controller.config.versions[version].ui_text

		//check version
		if(!Object.keys(this.props.controller.config.versions).includes(this.props.controller.version))
		{
			return <div style={{position:'absolute', top:'50%', right:'50%'}}>
				<h1>Version Error</h1>
				<h2>check URL query string <i>?version</i></h2>
			</div>
		}

		let question_idx
		let question
		let question_id
		let background_colour=this.props.controller.config.background_colour
		let num_questions
		let num_colours
		let mlpns

		switch(this.props.controller.showing)
		{
			case "intro":
				return  <div style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: '100vh',
					backgroundColor:background_colour
				}}>
					<Container fluid="true" style={{backgroundColor:background_colour}}>
						<Row>		
							<Col xs={2}></Col>
							<Col xs={8}>
								<h2 dangerouslySetInnerHTML={{__html: ui_text['intro']}}></h2>
								<p dangerouslySetInnerHTML={{__html: ui_text['terms']}}></p>
							</Col>
							<Col xs={2}></Col>
						</Row>
						<Row style={{marginTop:'10px'}}>
							<Col xs={2}></Col>
							<Col  xs={8}>
								<Button 
									size="lg"
									variant="outline-dark"
									onClick={this.onIntroDone.bind(this)}>
									{ui_text['begin']}
								</Button>
							</Col>
							<Col xs={2}></Col>
						</Row>
					</Container>
				</div>

			case "question+map":
				question_idx=this.props.controller.question_idx
				question=this.props.controller.question_seq[question_idx]
				question_id=parseInt(question.question)
				let question_id_previous=null
				if(question_idx>0)
				{
					question_id_previous=parseInt(this.props.controller.question_seq[question_idx-1].question)
				}

				num_questions=this.props.controller.question_seq.length
				num_colours=Object.keys(this.props.controller.config.colours).length
				return this.props.controller.questions_loaded ? 
				<Container 
					fluid
					style={{backgroundColor:background_colour, height:'100vh'}}>

					<Row>
						{/* map */}
						{question_idx>0 && <Col 
							xs={{span:12,order:2}} 
							md={{span:6,order:2}}//{/*responsive grid - stack on mobile, side by side on desktop*/}
							style={{
									paddingLeft:'0px',
									paddingRight:'0px'}}>
							{question_id_previous && 
							<div>
								<MapBox id={question_id_previous} colour={(question_idx-1)%num_colours}></MapBox>
								{/* legend showing answer */}
								<div style={{
									position:'absolute',
									top:'20px',
									left:'30px',
									maxWidth:'70%',
									zIndex:'100',
									fontSize:'14pt',
									backgroundColor:'white',
									border:'2px solid',
									borderColor:'black',
									padding:'5px',
									}}>
										<i>{ui_text['previous']}</i><br/>
										{this.props.controller.answers[question_id_previous].answer_text.map((t,i)=>{return <div key={i}>{t}</div>})}
								</div>
							</div>}
						</Col>}

						{/* question */}
						<Col 
							xs={{span:12,order:1}} 
							md={{span:question_idx>0 ? 6 : 12,order:1}}>
							<Container 	fluid="true" 
								className={`colour-${question_idx%num_colours}`}
								style={{backgroundColor:background_colour,
										marginTop:'50px',
										marginBottom:'50px'}}>
								<Row>		
									<Col></Col>
									<Col xs="8">
										<h4>{ui_text['question']} {question_idx+1}</h4>
									</Col>
									<Col></Col>
								</Row>
								<Row><h4></h4></Row>
								<Row>		
									<Col></Col>
									<Col xs="8">
										{/* <h4>{question.question_text}</h4> */}
										<h4 dangerouslySetInnerHTML={{__html: question.question_text}}></h4>
									</Col>
									<Col></Col>
								</Row>
								<Row style={{marginTop:'10px'}} >
									<Col></Col>
									<Col xs="8">
										<ToggleButtonGroup 
											type="checkbox" 
											vertical
											value={this.state.answer_toggles}
											onChange={(val)=>{this.setState({answer_toggles:val})}}
											>
											{question.variant_text.map((variant_text,i) => {
											return <ToggleButton
												key={`variant-${i}`}
												variant={`button-${question_idx%num_colours}`} //bootstrap button variant!!!
												size="lg"
												value={question.variant[i]+"::"+variant_text} //variant::variant_text
												>
												{variant_text}
											</ToggleButton>	})}
										</ToggleButtonGroup>
									</Col>
									<Col></Col>
								</Row>
								<Row style={{marginTop:'20px'}}>		
									<Col></Col>
									{this.props.controller.question_set=='primary' ? 
									<Col xs="8">
										<Button 
											size="lg"
											variant={`button-${question_idx%num_colours}`}
											onClick={this.onQuestionAnswered.bind(this)}
											disabled={this.state.answer_toggles.length==0}>
											{ui_text['next']}
										</Button>
									</Col> :
									<>
										<Col xs="4">
											<Button 
												size="lg"
												variant={`button-${question_idx%num_colours}`}
												onClick={this.onQuestionAnswered.bind(this)}
												disabled={this.state.answer_toggles.length==0}>
												{ui_text['next']}
											</Button>
										</Col>
										<Col xs="4">								
											<Button 
												size="lg"
												variant={`button-${question_idx%num_colours}`}
												onClick={this.onQuestionAnswered.bind(this)}
												disabled={this.state.answer_toggles.length==0}>
												{ui_text['finish']}
											</Button>
										</Col>
									</>
									}
									<Col></Col>
								</Row>
								<Row style={{marginTop:'20px'}}>		
									<Col></Col>
									<Col xs="8">
										<ProgressBar 
											now={100*(question_idx)/num_questions} 
											//style={{backgroundColor:Object.values(this.props.controller.config.colours)[question_idx%num_colours]}}
											// label={`${question_idx}/${num_questions}`}
										/>
										<p>{`${question_idx+1}/${num_questions}`}</p>
									</Col>
									<Col></Col>
								</Row>
							</Container>
						</Col>
					</Row>
				</Container>  : <div style={{position:'absolute', top:'50%', right:'50%'}}>
							<Spinner animation="border" role="status" variant="secondary"/></div>

			case "waiting":
				return <div style={{position:'absolute', top:'50%', right:'50%'}}>
				<Spinner animation="border" role="status" variant="secondary"/></div>

			case "predemographics":
				mlpns=this.props.controller.most_likely_placenames
				return <Container 
						fluid
						style={{backgroundColor:background_colour, height:'100%', width:'100%'}}>
						<Row>
							{/* result */}
							<Col 
								xs={{span:12,order:2}} 
								md={{span:6,order:2}}//{/*responsive grid - stack on mobile, side by side on desktop*/}
								style={{
									paddingLeft:'0px',
									paddingRight:'0px',
									}}>
								<div 
									style={{
										display: 'flex',
										position:'sticky',
										top:'0',
										left:'0'
									// // 	// alignItems: 'center',
									// // 	// justifyContent: 'center',
									// // 	// height: '100vh',
									// // 	// backgroundColor:background_colour
									}}
								>
									<Container fluid={true}>
										<Row>
											<Col>
												<MapBox></MapBox>
											</Col>
										</Row>
									</Container>
									{/* legend showing answer */}
									<div style={{
										position:'absolute',
										top:'20px',
										left:'30px',
										maxWidth:'70%',
										zIndex:'100',
										fontSize:'14pt',
										backgroundColor:'white',
										border:'2px solid',
										borderColor:'black',
										padding:'5px',
										}}>
											<h4><i>{ui_text['prediction']}</i></h4>
											{mlpns.length>2 && <h4>{ui_text['from']} {`${mlpns[0][0]}, ${mlpns[1][0]} or ${mlpns[2][0]}`}.</h4>}
											{/* <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" 
											class="twitter-share-button" 
											data-show-count="false"
											data-size="large" 
											data-text={`${ui_text['tweet']} ${mlpns[0][0]}, ${mlpns[1][0]} or ${mlpns[2][0]}`}
											>Tweet</a> */}
											<a class="twitter-share-button"
											href={`https://twitter.com/intent/tweet?text=${ui_text['tweet']} ${mlpns[0][0]}, ${mlpns[1][0]} or ${mlpns[2][0]}`}
											data-size="large">
											Tweet</a>
									</div>
								</div>
							</Col>

							{/* demographics */}
							<Col 
								xs={{span:12,order:1}} 
								md={{span:6,order:1}}>
								<div style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													height: '100vh',
													backgroundColor:background_colour
											}}>
										<Container fluid="true" style={{backgroundColor:background_colour}}>
											<Row>		
												<Col xs={2}></Col>
												<Col xs={8}>
													<h2 dangerouslySetInnerHTML={{__html: ui_text['predemographics']}}></h2>
												</Col>
												<Col xs={2}></Col>
											</Row>
											<Row style={{marginTop:'10px'}}>
												<Col xs={2}></Col>
												<Col  xs={8}>
													<Button 
														size="lg"
														variant="outline-dark"
														onClick={this.onPredemographicsDone.bind(this)}>
														{ui_text['begin']}
													</Button>
												</Col>
												<Col xs={2}></Col>
											</Row>
										</Container>
									</div>							
							</Col>
						</Row>
				</Container>

			case "demographics":
				mlpns=this.props.controller.most_likely_placenames
				return <Container 
						fluid
						style={{backgroundColor:background_colour, height:'100%', width:'100%'}}>
						<Row>
							{/* result */}
							<Col 
								xs={{span:12,order:2}} 
								md={{span:6,order:2}}//{/*responsive grid - stack on mobile, side by side on desktop*/}
								style={{
									paddingLeft:'0px',
									paddingRight:'0px',
									}}>
								<div 
									style={{
										display: 'flex',
										position:'sticky',
										top:'0',
										left:'0'
									// // 	// alignItems: 'center',
									// // 	// justifyContent: 'center',
									// // 	// height: '100vh',
									// // 	// backgroundColor:background_colour
									}}
								>
									<Container fluid={true}>
										<Row>
											<Col>
												<MapBox></MapBox>
											</Col>
										</Row>
									</Container>
									{/* legend showing answer */}
									<div style={{
										position:'absolute',
										top:'20px',
										left:'30px',
										maxWidth:'70%',
										zIndex:'100',
										fontSize:'14pt',
										backgroundColor:'white',
										border:'2px solid',
										borderColor:'black',
										padding:'5px',
										}}>
											<h4><i>{ui_text['prediction']}</i></h4>
											{mlpns.length>2 && <h4>{ui_text['from']} {`${mlpns[0][0]}, ${mlpns[1][0]} or ${mlpns[2][0]}`}.</h4>}
											{/* <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" 
											class="twitter-share-button" 
											data-show-count="false"
											data-size="large" 
											data-text={`${ui_text['tweet']} ${mlpns[0][0]}, ${mlpns[1][0]} or ${mlpns[2][0]}`}
											>Tweet</a> */}
											<a class="twitter-share-button"
											href={`https://twitter.com/intent/tweet?text=${ui_text['tweet']} ${mlpns[0][0]}, ${mlpns[1][0]} or ${mlpns[2][0]}`}
											data-size="large">
											Tweet</a>
									</div>
								</div>
							</Col>

							{/* demographics */}
							<Col 
								xs={{span:12,order:1}} 
								md={{span:6,order:1}}>
								<Demographics/>
							</Col>
						</Row>

				</Container>


			case "thanks1":
			case "thanks2":
				mlpns=this.props.controller.most_likely_placenames
				return <Container 
					fluid
					style={{backgroundColor:background_colour, height:'100vh'}}>

						<Row>
							{/* result */}
							<Col 
								xs={{span:12,order:2}} 
								md={{span:6,order:2}}//{/*responsive grid - stack on mobile, side by side on desktop*/}
								>

								<div style={{
									display: 'flex',
									position:'sticky',
									top:'0',
									alignItems: 'center',
									justifyContent: 'center',
									height: '100vh'
								}}>
									<Container fluid={true}>
										<Row>
											<Col>
												<MapBox></MapBox>
											</Col>
										</Row>
									</Container>
									{/* legend showing answer */}
									<div style={{
										position:'absolute',
										top:'20px',
										left:'30px',
										maxWidth:'70%',
										zIndex:'100',
										fontSize:'14pt',
										backgroundColor:'white',
										border:'2px solid',
										borderColor:'black',
										padding:'5px',
										}}>
											<h4><i>{ui_text['prediction']}</i></h4>
											{mlpns.length>2 && <h4>{ui_text['from']} {`${mlpns[0][0]}, ${mlpns[1][0]} or ${mlpns[2][0]}`}.</h4>}
											{/* <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" 
											class="twitter-share-button" 
											data-show-count="false"
											data-size="large" 
											data-text={`${ui_text['tweet']} ${mlpns[0][0]}, ${mlpns[1][0]} or ${mlpns[2][0]}`}
											>Tweet</a> */}
											<a class="twitter-share-button"
											href={`https://twitter.com/intent/tweet?text=${ui_text['tweet']} ${mlpns[0][0]}, ${mlpns[1][0]} or ${mlpns[2][0]}`}
											data-size="large"></a>
									</div>
								</div>
							</Col>

							{/* thanks */}
							<Col 
								xs={{span:12,order:1}} 
								md={{span:6,order:1}}>
								<div style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												height: '100vh',
												backgroundColor:background_colour
											}}>
									<Container fluid="true" style={{backgroundColor:background_colour}}>
										<Row>		
											<Col xs={2}></Col>
											<Col xs={8}>
											<h2 dangerouslySetInnerHTML={{__html: this.props.controller.showing=='thanks1' ? ui_text['thankyou1'] : ui_text['thankyou2']}}></h2>
											</Col>
											<Col xs={2}></Col>
										</Row>
										{this.props.controller.showing=='thanks1' && <Row>		
											<Col xs={2}></Col>
											<Col xs={4}>
											{/* continue button */}
												<Button 
													size="lg"
													variant="outline-dark"
													onClick={this.onMore.bind(this)}>
													{ui_text['continue']}
												</Button>
											</Col>
											<Col xs={4}>
											{/* no thanks button */}
												<Button 
													size="lg"
													variant="outline-dark"
													onClick={this.onNothanks.bind(this)}>
													{ui_text['nothanks']}
												</Button>
											</Col>
											<Col xs={2}></Col>
										</Row>}
									</Container>
								</div>
							</Col>
						</Row>

				</Container>
		}
	}
}

const mapState = (state, props) => 
{
    return state
}

const mapDispatch=
{
	setShowing, setDevelop, setBorder, setNumquestions,
	setVersion, fetchConfig, startQuestions, setAnswer, uploadAnswer, nextQuestion, fetchDataset,
	computeResult, waitForResult, startDemographics, startPredemographics, initFirebase, moreQuestions
}

export default connect(mapState,mapDispatch)(Controller)
